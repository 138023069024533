import { createGlobalStyle } from 'styled-components';
import { accent } from 'constants/theme';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    color: #212529;
  }

  h1 {
    font-size: 2.5em;
    margin: 10px;
  }

  h2 {
    font-size: 1.4em;
    margin: 60px 0 0;
    border-top: 1px solid #ececec;
    padding-top: 30px;
  }

  h3 {
    font-weight: 600;
    font-size: 1.2em;
    margin: 40px 20px 20px;
  }
  

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1.6rem;
    color: #000;
    background-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${accent};
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${accent};
    border: 1px solid #ddd;
    font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }
  .dot {
    height: 20px;
    width: 20px;
    background-color: rgb(123,199,111);
    border-radius: 50%;
    display: inline-block;
    padding: 6px 6px;
    text-align: center;
    margin: 0 10px;
    color: #FFF;
  }

  button {
    font-size: 13px;
    text-align: center;
    color: #000;
    cursor: pointer;
    outline: none;
    padding: 12px 60px;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
    background-color: rgb(123, 199, 111);
    border-radius: 6px;
    letter-spacing: 1.5px;
    margin-top: 10px;
    border: 0px;
  }
  .oppositeButton {
    border: 1px solid rgb(123, 199, 111);
    color: rgb(123, 199, 111);
    background-color: #FFFFFF;
  }
  .itemBoxSelected{
    border-radius: 5px;
    box-shadow: none;
    border: 3px solid #7bc96f;
    box-shadow: 2px 5px 10px rgba(0,0,0,0.1);
  }
  .itemBoxSelected img{
    border-radius: 5px 5px 0 0;
  }
`;
