import React from 'react';
import { FooterElt } from './footer.css';
/* eslint-disable */
const Footer = () => (
  <FooterElt>
    © Copyright 2019 - www.gitbrick.com - Follow us on <a
      href="https://twitter.com/GitBrick"
      target="_blank"
      rel="noopener noreferrer"
    >
      Twitter
    </a>
  </FooterElt>
);

export default Footer;
