import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import posed from 'react-pose';
import { Container } from './header.css';
import Nav from 'components/header/nav';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = ({ title }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "images/logo-gitbrick.png" }) {
          childImageSharp {
            fixed(width: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <AnimatedContainer>
        <Container>
          <Link to="/">
            <Img fixed={data.file.childImageSharp.fixed} alt={title} />
          </Link>

          <Nav />
        </Container>
      </AnimatedContainer>
    )}
  />
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
