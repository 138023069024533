import React from 'react';

import { Container } from './nav.css';

const Nav = () => (
  <Container>
    <ul>
      <li></li>
    </ul>
  </Container>
);

export default Nav;
