import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';

const Confirmed = ({ data }) => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Head pageTitle={data.confirmedJson.title} />
    <Box>
      <Img
        fixed={
          data.confirmedJson.image
            ? data.confirmedJson.image.childImageSharp.fixed
            : {}
        }
        alt="Order Confirmed"
      />
      <div
        dangerouslySetInnerHTML={{
          __html: data.confirmedJson.content.childMarkdownRemark.html,
        }}
      />
      <Link to="/">Back to home</Link>
    </Box>
  </Layout>
);

Confirmed.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Confirmed;

export const query = graphql`
  query ConfirmedQuery {
    confirmedJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        childImageSharp {
          fixed(width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;
